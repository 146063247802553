import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

function setInterceptors(instance: AxiosInstance): AxiosInstance {
  instance.interceptors.request.use(
    async function (config) {
      return config;
    },
    function (error: AxiosError) {
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response: AxiosResponse) {
      return response;
    },
    function (error: AxiosError) {
      return Promise.reject(error);
    },
  );
  return instance;
}

export { setInterceptors };
