import { AxiosResponse } from 'axios';
import { axiosCommon } from '../https';
import { OddList, RecentSport, ScoreOdd, WiseOdd } from '@/types/types';

const commonApi = {
  dateFormat(date: Date) {
    let month: number | string = date.getMonth() + 1;
    let day: number | string = date.getDate();
    let hour: number | string = date.getHours();
    let minute: number | string = date.getMinutes();
    let second: number | string = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
  },
  getScoreOdds(
    leaguePath: string,
    win: string,
    tie: string,
    lose: string,
    odd: string,
    handicap: string,
    handicapOdd: string,
  ): Promise<AxiosResponse<{ list: ScoreOdd[] }>> {
    const url = `/for_odd_list`;
    const method = 'post';
    const data = {
      league_path: leaguePath,
      win_pro: win,
      tie_pro: tie,
      lose_pro: lose,
      odd: odd,
      handicap: handicap,
      handicap_odd: handicapOdd,
    };

    return axiosCommon(url, method, data);
  },
  getWiseOdds(
    sportType: number,
    win: string,
    lose: string,
    handi: number,
    teamName: string,
    year: number,
    handicap: string,
  ): Promise<AxiosResponse<{ list: WiseOdd }>> {
    const url = `/w_odd_list`;
    const method = 'post';
    const data = {
      odd_win: win,
      odd_lose: lose,
      handicap: handicap,
      home_team_name: teamName,
      year: year,
      sport_type: sportType,
      handi: handi,
    };

    return axiosCommon(url, method, data);
  },
  getRecentSports(): Promise<AxiosResponse<{ datas: RecentSport[] }>> {
    const url = `/betman`;
    const method = 'post';
    const data = {};

    return axiosCommon(url, method, data);
  },
  getBetmanEtcOddList(
    year: number,
    homeTeam: string,
    winHandi: number,
  ): Promise<AxiosResponse<OddList[]>> {
    const url = `/get_odd_list?year=${year}&homeTeam=${homeTeam}&winHandi=${winHandi}`;
    const method = 'post';
    const data = {};

    return axiosCommon(url, method, data);
  },
  getBetmanSoccerOddList(
    year: number,
    handiType: number,
    winAllot: number,
    loseAllot: number,
  ): Promise<AxiosResponse<OddList[]>> {
    const url = `/get_odd_list_soccer?year=${year}&handiType=${handiType}&winAllot=${winAllot}&loseAllot=${loseAllot}`;
    const method = 'post';
    const data = {};

    return axiosCommon(url, method, data);
  },
  getForebetOddList(
    leagueName: string,
    homeTeam: string,
    awayTeam: string,
  ): Promise<AxiosResponse<{ list: ScoreOdd[]; error_msg: Array<string[]> }>> {
    const url = `/get_pre_game?leagueName=${leagueName}&homeTeam=${homeTeam}&awayTeam=${awayTeam}`;
    const method = 'post';
    const data = {};

    return axiosCommon(url, method, data);
  },
};
export default commonApi;
