import React, { MouseEvent } from 'react';

type Props = {
  leaguePath: string;
  handleLeaguePath: (path: string) => void;
};

export default function ScoreChips({ leaguePath, handleLeaguePath }: Props) {
  const leagues = ['Europe', 'Kor', 'Jap', 'USA', 'Aus'];

  const handleLeague = (e: MouseEvent<HTMLDivElement>) => {
    const eventTarget = e.target as HTMLElement;
    handleLeaguePath(eventTarget.innerText);
  };

  return (
    <div className=" bg-white px-9 py-8 flex justify-between">
      {leagues &&
        leagues.map((league) => (
          <div
            key={league}
            className={`border-2 border-sky-600 px-16 py-4 rounded-3xl font-bold cursor-pointer ${
              leaguePath === league ? `text-white bg-sky-600` : `text-sky-600`
            }`}
            onClick={handleLeague}
          >
            {league}
          </div>
        ))}
    </div>
  );
}
