import { useRoutes } from 'react-router-dom';
import { lazy } from 'react';
import Score from '@/pages/Score';
// const Home = lazy(() => import('@/pages/Home'));
const Home = lazy(() => import('../pages/Home'));
const Wise = lazy(() => import('../pages/Wise'));
// const MainLayout = lazy(() => import('@/layouts/MainLayout'));

function Router() {
  const routes = useRoutes([
    {
      // path: '/',
      // element: <MainLayout />,
      // children: [
      //   {
      //     path: '/',
      //     element: <Home />,
      //   },
      // ],
      path: '/',
      element: <Home />,
    },
    { path: '/score', element: <Score /> },
    { path: '/wise', element: <Wise /> },
  ]);
  return routes;
}

export default Router;
