import commonApi from '@/api/common/CommonApi';
import { ScoreOdd } from '@/types/types';
import React, { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';

type Props = {
  leaguePath: string;
  handleOdds: (odds: ScoreOdd[]) => void;
  handleLoading: Dispatch<SetStateAction<boolean>>;
};

export default function ScoreForm({ leaguePath, handleOdds, handleLoading }: Props) {
  const [win, setWin] = useState(''); // 이길 확률
  const [tie, setTie] = useState(''); // 무승부 확률
  const [lose, setLose] = useState(''); // 패배 확률
  const [odd, setOdd] = useState(''); // 배당률
  const [handicap, setHandicap] = useState(''); // 핸디캡
  const [handicapOption, setHandicapOption] = useState([true, false]);
  const [handicapOdd, setHandicapOdd] = useState(''); // 핸디캡 odd

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (handicapOption[0] === false && handicapOption[1] === false)
      return alert('핸디캡이 Home인지 Away인지 체크해주세요.');

    handleLoading(true);
    const res = await commonApi.getScoreOdds(
      leaguePath,
      win,
      tie,
      lose,
      odd,
      handicap,
      handicapOdd,
    );
    handleLoading(false);

    console.log('Odd 조회 결과 => ', res.data.list);
    handleOdds(res.data.list);
  };

  useEffect(() => {
    if (handicapOption[0]) setHandicap('Home 0');
    else if (handicapOption[1]) setHandicap('Away 0');
    else setHandicap('위에있는 체크박스를 선택해주세요.');
  }, [handicapOption]);

  return (
    <form
      className=" bg-sky-100 border-t-2 border-b-2 py-12 px-12 border-sky-600 grid grid-cols-3 gap-14"
      onSubmit={handleSubmit}
    >
      <div>
        <label className={labelStyle}>Win_probability</label>
        <input
          type="text"
          autoFocus
          className={inputStyle}
          placeholder="승리확률"
          required
          value={win}
          onChange={(e) => setWin(e.target.value)}
        />
      </div>
      <div>
        <label className={labelStyle}>Tie_probability</label>
        <input
          type="text"
          className={inputStyle}
          placeholder="무승부 확률"
          required
          value={tie}
          onChange={(e) => setTie(e.target.value)}
        />
      </div>
      <div>
        <label className={labelStyle}>Lose_probability</label>
        <input
          type="text"
          className={inputStyle}
          placeholder="패배 확률"
          required
          value={lose}
          onChange={(e) => setLose(e.target.value)}
        />
      </div>
      <div>
        <label className={labelStyle}>Odd</label>
        <input
          type="text"
          className={inputStyle}
          placeholder="odd - 소수점"
          required
          value={odd}
          onChange={(e) => setOdd(e.target.value)}
        />
      </div>
      <div>
        <div className={labelStyle}>
          <input
            type="checkbox"
            id="homebox"
            className="mr-2"
            onChange={(e) => setHandicapOption([e.target.checked, false])}
            checked={handicapOption[0]}
          />
          <label htmlFor="homebox" className="mr-6">
            Home
          </label>
          <input
            type="checkbox"
            id="awaybox"
            className="mr-2"
            onChange={(e) => setHandicapOption([false, e.target.checked])}
            checked={handicapOption[1]}
          />
          <label htmlFor="awaybox">Away</label>
        </div>
        <input
          type="text"
          className={inputStyle}
          placeholder="handicap - 숫자"
          required
          value={handicap}
          onChange={(e) => setHandicap(e.target.value)}
        />
      </div>
      <div>
        <label className={labelStyle}>Handicap_odd</label>
        <input
          type="text"
          className={inputStyle}
          placeholder="handicap_odd"
          required
          value={handicapOdd}
          onChange={(e) => setHandicapOdd(e.target.value)}
        />
      </div>
      <button className="bg-sky-600 rounded-xl py-3 text-white mt-10 font-bold">검색</button>
    </form>
  );
}

const labelStyle = `text-gray-600 flex`;
const inputStyle = `border border-gray-400 rounded-xl outline-sky-600 w-full px-2 py-3 mt-1`;
