import { ScoreOdd } from '@/types/types';
import React from 'react';
import { GridLoader } from 'react-spinners';

const titles = [
  'probability',
  'odds',
  'handicap',
  'odds_handicap',
  'home_team',
  'away_team',
  'score',
  'real_winner',
  'play_date',
];

type Props = {
  odds: ScoreOdd[];
  loading: boolean;
};

export default function ScoreTable({ odds, loading }: Props) {
  return (
    <section className="w-full mt-10 max-h-[800px] overflow-y-scroll">
      {loading && (
        <div className="w-full text-center">
          <GridLoader color="blue" />
        </div>
      )}
      {odds.length === 0 && loading === false && (
        <p className=" text-2xl text-center font-bold">검색 결과가 없습니다.</p>
      )}
      {odds.length !== 0 && loading === false && (
        <table className="w-full">
          <thead className=" sticky top-0">
            <tr>
              {titles &&
                titles.map((title) => (
                  <th className="bg-gray-100 py-5 " key={title}>
                    {title}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {odds.map((odd) => (
              <tr key={odd.id} className=" text-sm text-center border-b">
                <td className={tdStyle}>{odd.probability}</td>
                <td className={tdStyle}>{odd.odds}</td>
                <td className={`${tdStyle} flex justify-center items-center`}>
                  <div
                    className={`${
                      odd.handicap.split(' ')[0] === 'Home' ? 'bg-green-600' : 'bg-purple-500'
                    } text-white px-2 py-1 rounded-md mr-1`}
                  >
                    {odd.handicap.split(' ')[0]}
                  </div>
                  <p>{odd.handicap.split(' ')[1]}</p>
                </td>
                <td className={tdStyle}>{odd.odds_handicap}</td>
                <td className={tdStyle}>{odd.home_team}</td>
                <td className={tdStyle}>{odd.away_team}</td>
                <td className={tdStyle}>{odd.score}</td>
                <td className={tdStyle}>{odd.real_winner}</td>
                <td className={tdStyle}>{odd.play_date.substring(0, 10)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
}

const tdStyle = `py-4 text-gray-500`;
