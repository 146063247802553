// import styles from '@/pages/Home.module.scss';
// import styles from '../pages/Home.module.scss';
// import classNames from 'classnames/bind';
import ScoreChips from '@/components/chips/ScoreChips';
import ScoreForm from '@/components/forms/ScoreForm';
import ScoreTable from '@/components/tables/ScoreTable';
import { ScoreOdd } from '@/types/types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
// const cx = classNames.bind(styles);
// `${cx('testzz')}`

function Score() {
  const [loading, setLoading] = useState(false);
  const [leaguePath, setLeaguePath] = useState('Europe');
  const [odds, setOdds] = useState<ScoreOdd[]>([]);
  const handleLeaguePath = (path: string) => {
    setLeaguePath(path);
  };
  const handleOdds = (odds: ScoreOdd[]) => {
    setOdds(odds);
  };

  return (
    <section className=" max-w-screen-lg mx-auto pb-10">
      <nav className="flex justify-between mt-8">
        <div>
          <Link
            to={'/'}
            className="bg-sky-600 p-3 rounded-lg text-white"
            aria-label="wise 페이지 이동 링크"
          >
            Home 페이지로 이동
          </Link>
        </div>
        <div className="flex gap-6">
          <Link
            to={'/score'}
            className="bg-sky-600 p-3 rounded-lg text-white"
            aria-label="wise 페이지 이동 링크"
          >
            score 페이지로 이동
          </Link>
          <Link
            to={'/wise'}
            className="bg-sky-600 p-3 rounded-lg text-white"
            aria-label="wise 페이지 이동 링크"
          >
            wise 페이지로 이동
          </Link>
        </div>
      </nav>
      <ScoreChips leaguePath={leaguePath} handleLeaguePath={handleLeaguePath} />
      <ScoreForm leaguePath={leaguePath} handleOdds={handleOdds} handleLoading={setLoading} />
      <ScoreTable odds={odds} loading={loading} />
    </section>
  );
}

export default Score;
